body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.label {
  font-weight: 700;
  margin-left: 5px;
  color: #be9996;
}

.titulo_lista {
  font-size: 18px;
  font-weight: 600;
  color: #ca2228;
  margin-left: 50px;
}

.rowArchivos {
  padding: 3px;
  border: 1px solid silver;
  margin-bottom: 3px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
}

.botonCirculo {
  margin: auto;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  text-align: center;
  line-height: 130px;
  font-size: 30px;
  cursor: pointer;
}

.amarillo {
  background-color: #ffffc9;
  color: #1890ff;
}

.amarillo:hover {
  background-color: yellow;
}

.verde {
  background-color: #b4f0bc;
  color: orange;
}

.verde:hover {
  background-color: green;
}

.rowArchivos:hover {
  background-color: #f1f1f1;
  color: #ca2228;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sinConfirmar {
  padding: 20px;
  text-align: center;
  background-color: #ca2228;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
}

:root {
  --color-grisOscuro: #666;
  /* --color-naranja: #ffa500; */
  --color-naranja: #ca2228;
  --color-azul: #001149;
}

.crono {
  background-color: #001149;
  color: #fff;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
}

/*  */

.logo {
  height: 40px;
}

.input {
  border-radius: 5px;
}

.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #1890ff !important;
  font-weight: bold !important;
}

.botonCard {
  color: #ca2228;
  margin-top: 2px;
  float: right;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  padding: 3px 5px;
}

.contenedorHistorial {
  padding: 10px;
  max-height: 126px;
  overflow-y: auto;
  font-size: 13px;
}

.botonCard2 {
  color: #ca2228;
  width: 80px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  height: 31px;
  line-height: 31px;
}

.botonCardL {
  color: #ca2228;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  max-width: 180px;
  margin-bottom: 5px;
  padding: 0 5px;
}

.botonCardLeft {
  color: #ca2228;
  margin-top: 2px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.botonCardLeft2 {
  color: #ca2228;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.botonCard:hover,
.botonCardL:hover,
.botonCard2:hover,
.botonCardLeft:hover {
  border-radius: 5px;
  background-color: #ddd;
  opacity: 1;
}

.card__titulo {
  margin-left: 15px;
  margin-bottom: 5px;
  color: var(--color-naranja);
  font-weight: 500;
  font-size: 20px;
}

.card__accion {
  text-align: right;
  padding-bottom: 5px;
  padding-right: 15px;
}

.card__contenido {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
  border-radius: 5px;
}

.card__contenido_sin {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.inputLabel {
  color: var(--color-grisOscuro);
  margin-left: 5px;
}

.botonSubmit {
  border: 1px solid var(--color-naranja);
  color: var(--color-naranja);
  border-radius: 5px;
  text-align: center;
  padding: 3px 15px;
  cursor: pointer;
}

.botonSubmitDisabled {
  border: 1px solid #f1f1f1;
  color: #999;
  border-radius: 5px;
  text-align: center;
  padding: 3px 3px;
  cursor: not-allowed;
  background-color: #f1f1f1;
}

.contenedorBotonSubmit {
  padding: 15px 0 15px 15px;
}

.botonSubmit:hover {
  background-color: var(--color-naranja);
  color: #fff;
  border: 1px solid var(--color-naranja);
}

.botonSubmit:disabled {
  border: 0px solid var(--color-naranja);
}

.eyeIcon {
  opacity: 0.5;
  cursor: pointer;
}

.SinRegistros {
  margin-left: 50px;
  padding: 20px;
  color: #666;
}

.SinRegistrosImg {
  height: 50px;
}

.eyeIcon:hover {
  opacity: 1;
}

.blink {
  animation: blink-animation 2s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.tabla {
  border-collapse: collapse;
  width: 80%;
}

.renglonInstruccioneds {
  padding: 5px;
  border: 1px solid #f1f1f1;
  margin-bottom: 10px;
}
.facturaRechazada {
  font-size: 11px;
  cursor: pointer;
  padding: 4px 0;
  color: #ca2228;
}

.tituloGrid {
  font-weight: 600;
  color: #be9996;
  padding-left: 10;
  border-bottom: 1px solid #be9996;
  text-align: center;
}

.tituloaModal {
  color: rgb(2, 76, 76);
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 20px;
  padding-left: 10px;
}

.mapaIcon {
  height: 25px;
  cursor: pointer;
  opacity: 0.5;
}

.mapaIcon:hover {
  opacity: 1;
}

.rowCampos {
  padding: 2px;
}

.colLabelCampos {
  background-color: rgb(16, 17, 75);
  color: white;
  text-align: right;
  border: 1px solid rgb(135, 136, 192);
}

.colValueCampos {
  border: 1px solid rgb(135, 136, 192);
}

.celdaNumero {
  text-align: right;
  padding-right: 10px;
}

.labelGrid {
  text-align: right;
  font-weight: 600;
}

.imgPosicion {
  height: 110px;
  margin-top: 25px;
}

.textoNaranja {
  font-weight: 600;
  color: var(--color-naranja);
}

.renglonArticulo:hover {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
}

.rowItem {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  cursor: pointer;
 
  background: url(/src/assets/chevron-right.png) no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 15px;
  border-radius: 5px;
  margin: 0px 0;
}

.rowItemElementoSmall {
  padding: 5px 5px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  border-radius: 5px;
}
.rowItem:hover,
.rowItemElemento:hover,
.rowItemElementoSmall:hover {
  background-color: #fcfcfc;
}


.rowItemElementoSmall2 {
  padding: 5px 5px;
  color: #333;
}

.column-title {
  background-color: #F3F3F3; /* Color de fondo similar al de Excel */
  border: 1px solid #D4D4D4; /* Borde similar al de Excel */
  font-weight: bold; /* Texto en negrita */
  text-align: center; /* Alineación centrada */
  padding: 3px; /* Espaciado interno */
  color: #666;
}

.data-field {
  background-color: #FFFFFF; /* Color de fondo blanco */
  border: 1px solid #D4D4D4; /* Borde similar al de Excel */
  text-align: left; /* Alineación a la izquierda */
  padding: 3px 3px 3px 5px; /* Espaciado interno */
}


.tituloCampo {
  font-weight: 600;
  color: #666;
  padding-left: 10px;
  background-color: #f1f1f1;
  padding-left: 10px;

}

.subTitulo {
  font-size: 16px;
  font-weight: 600;
  color: teal;
  padding-bottom: 15px;

}

.selectedItem,
.selectedItem:hover {
  background-color: #f1f1f1;
}

.spaceChildren {
  padding-top: 7px;
}

.spaceChildren > * {
  margin: 0 5px;
}

.rowItemElemento {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  border-radius: 5px;
}

.rowItem:last-of-type,
.rowItemElemento:last-of-type {
  border-bottom: 0px solid #f1f1f1;
}

.escojaCategoria {
  text-align: center;
  padding: 50px 0;
  color: #666;
}

.ver {
  color: #f1f1f1;
  font-size: 12px;
}

.IconCheck {
  font-size: 25px;
  color: #666;
}

.IconCheck:hover {
  color: var(--color-naranja);
}

.valor {
  padding: 2px 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  min-height: 26px;
}

.fileItem {
  cursor: pointer;
}

.fileItem:hover {
  background-color: #f1f1f1;
  color: var(--color-naranja);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.tituloLogin {
  text-align: center;

  color: #f1f1f1;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
}

.serviciosEnOrden {
  text-align: center;
  cursor: pointer;
}

.listaAsistencias {
  padding: 4px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin-bottom: 3px;
}

.serviciosEnOrden:hover {
  color: #ca2228;
}

.full {
  height: 100vh;
  background-color: red;
  padding-top: 100px;
  background: url(/src/assets/backlogin.jpg);
  background-repeat: no-repeat;
  background-size: auto;
}

.loginCuadro {
  width: 350px;
  background-color: rgba(7, 60, 85, 0.5);
  padding: 20px 50px;
  border-radius: 50px;
  margin-left: 200px;
}

.historialVentana {
  font-size: 11px;
  overflow-x: hidden;
  max-height: 270px;
  overflow-y: scroll;
}

.logoLogin {
  height: 60px;
}

.logoContainer {
  padding-left: 270px;
  margin-bottom: 15px;
}

.labelImput {
  color: #999;
  margin-left: 5px;
}

.listaSelect {
  padding: 10px 10px;
  border: 1px #f1f1f1 solid;
  cursor: pointer;
  color: #999;
}

.listaSelect:hover {
  background-color: #f1f1f1;
  color: #000;
}

.seleccionado {
  background-color: #f1f1f1;
  color: #ca2228;
}

.listaVacia {
  margin-top: 30px;
  margin-left: 30px;
}

.tituloServicios {
  margin-left: 30px;
  color: #ca2228;
}

.tituloLista {
  margin-left: 30px;
  color: #ca2228;
  font-size: 16px;
  font-weight: 600;
}

.subtitulo {
  margin-bottom: 20px;
  font-size: 16px;
}

.subtituloMenos {
  margin-bottom: 10px;
  font-size: 14px;
  color: #ca2228;
  margin-left: 10px;
}

.okIcon {
  color: #1bcc0b;
  font-size: 18px;
  margin-top: 10px;
}

.errIcon {
  color: #ca2228;
  font-size: 18px;
  margin-top: 10px;
}

.bordeLista {
  border-bottom: 1px solid #ccc;
  padding-left: 10px;
}

.botonEliminar {
  opacity: 0.5;
  cursor: pointer;
}

.botonEliminar:hover {
  opacity: 1;
}

.tituloTabla {
  border-bottom: 1px solid #f1f1f1;
  color: #ca2228;
  font-weight: 600;
  /* text-align: center; */
}
.tituloInstrucciones {
  color: teal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  /* text-align: center; */
}
.tituloTabla2 {
  border: 1px solid #f1f1f1;
  text-align: center;
  background-color: #999;
  color: #f1f1f1;
  font-weight: 600;
  padding: 10px;
  border: 1px solid #999;
  /* text-align: center; */
}
.rowTabla2 {
  padding: 3px;
  border: 1px solid #999;
  text-align: center;
}

.rowTabla {
  padding: 5px;
}

.icon {
  cursor: pointer;
  font-weight: 600;
  opacity: 0.5;
  font-size: 20px;
  margin-right: 10px;
}

.item {
  border-bottom: 1px solid #f1f1f1;
}

.icon:hover {
  opacity: 1;
}

.map-container {
  height: 400px;
  width: 100%;
}

.map-container2 {
  height: 80vh;
  width: 100%;
}

.sucursalItem {
  padding: 5px;
}

.sucursalItemSelected {
  color: #ca2228;
}

.centroMapa {
  text-align: center;
  margin-top: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #ca2228;
}

.botnGeo {
  text-align: center;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.botnGeo:hover {
  background-color: #f1f1f1;
}

.line-30 {
  height: 30px;
  line-height: 30px;
}

.itemSitios {
  cursor: pointer;
}

.itemSitios:hover {
  color: #ccc;
}

.mapaContainer {
  height: 470px;
  width: 100%;
  margin-bottom: 10px;
}

.mapaDataContainer {
  z-index: 9999;
  position: "relative";
  width: "90%";
  padding-left: 10px;
}

.infoMapa {
  border-radius: 3px;
  background-color: rgba(100, 100, 100, 0.7);
  color: white;
  font-weight: 600;
  padding-left: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.logoCliente {
  height: 100px;
}

.textoFiltro {
  text-align: center;
  margin-bottom: 12px;
  color: #ca2228;
  font-weight: 600;
}

.rowAsistencias {
  border: 1px solid #ccc;
  padding: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.rowAsistencias:hover {
  border: 1px solid #333;
}

.rowFechaTabla {
  padding: 0 3px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;

  text-align: center;
}

.rowRojo {
  background-color: #ffc9c9;
}

.rowAmarillo {
  background-color: #ffffc9;
}

.rowNormalTabla {
  text-align: center;
}
